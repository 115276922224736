/*global se */
const apiUrl = se.gss.conf.basePath + '/api';

import { DateTime } from 'luxon';
import { fetchGraphData, fetchAllStatData } from './gss-core';
import {
    getCurrentDateHuge,
    getDateHuge,
    getMonth,
    getYearAndMonth,
    getTime,
    getDayAndDate
} from './gss-time';
import Highcharts from './gss-highcharts';
import { getWindDirection } from './../gss-wind';
var highChartOptions = {
    credits: {
        text: 'Källa: SMHI',
        href: 'https://www.smhi.se'
    },
    chart: {
        type: 'spline',
        panning: true,
        zoomType: 'x'
    },
    title: {
        text: 'Vind'
    },

    subtitle: {
        text: getCurrentDateHuge()
    },
    tooltip: {
        formatter: function () {
            let { x, y, key, point } = this;
            let { direction, date } = point;
            date = date || new Date(x).toISOString();
            return `${y} m/s ${
                direction ? getWindDirection(direction) : key
            } ${getTime(date)}`;
        }
    },
    xAxis: {
        type: 'datetime'
    },
    yAxis: {
        allowDecimals: false,
        min: 0,
        minRange: 10,
        title: {
            text: 'Vind (m/s)'
        }
    },
    plotOptions: {
        series: {
            marker: {
                enabled: false
            }
        }
    },
    legend: {
        align: 'center',
        verticalAlign: 'bottom',
        layout: 'vertical',
        x: 0,
        y: 0
    }
};
function addWindDirection(gustData, speedData, direction) {
    direction.forEach(({ x, y }) => {
        let windDirection = getWindDirection(y);
        let gust = gustData.find((g) => g.x === x);
        let speed = speedData.find((s) => s.x === x);
        if (gust) {
            gust.name = windDirection;
        }
        if (speed) {
            speed.name = windDirection;
        }
    });
    return { gustData, speedData };
}
function getMonthData(data, month) {
    if (data.length === 12) {
        return data[parseInt(month) - 1];
    } else {
        return data.find(({ x }) => {
            let monthOfValue = new Date(x).toLocaleDateString().split('-')[1];
            return monthOfValue === month;
        });
    }
}
async function showWindObservationForAllTimePerMonth(
    id,
    startYear,
    endYear,
    month
) {
    let totalGustData = [],
        totalSpeedData = [],
        monthName = DateTime.fromObject({
            year: startYear,
            month: month,
            day: 1
        })
            .setLocale('sv')
            .toFormat('LLLL'),
        highChart = Highcharts.chart(
            id,
            Object.assign({}, highChartOptions, {
                subtitle: {
                    text: monthName
                },
                tooltip: {
                    formatter: function () {
                        return (
                            this.y +
                            ' m/s ' +
                            this.key +
                            ' ' +
                            getYearAndMonth(new Date(this.x).toISOString())
                        );
                    }
                }
            })
        );
    for (let year = startYear; year <= endYear; year++) {
        let { gustData, speedData } = await fetchWindDataForYear(year),
            monthData;
        if (gustData.length) {
            monthData = getMonthData(gustData, month);
            if (monthData) {
                totalGustData.push(monthData);
            }
        }
        monthData = getMonthData(speedData, month);
        if (monthData) {
            totalSpeedData.push(monthData);
        }
    }
    highChart.addSeries(
        {
            data: totalSpeedData,
            name: 'Observation - medelvind',
            index: 1
        },
        false
    );
    highChart.addSeries({
        color: '#2b908f',
        dashStyle: 'Dot',
        data: totalGustData,
        name: 'Observation - Max byvind',
        index: 3
    });
}
async function showWindObservationForAllTime(id, startYear, endYear) {
    let totalGustData = [],
        totalSpeedData = [],
        highChart = Highcharts.chart(
            id,
            Object.assign({}, highChartOptions, {
                subtitle: {
                    text: `${startYear} - ${endYear}`
                },
                tooltip: {
                    formatter: function () {
                        return `${this.y} m/s ${this.key} ${getYearAndMonth(
                            new Date(this.x).toISOString()
                        )}`;
                    }
                }
            })
        );
    for (let year = startYear; year <= endYear; year++) {
        const { gust, speed } = await fetchAllStatData({
            url: `/wind/${year}`
        });
        const maxGustData = gust.reduce(
                (prev, curr) => {
                    return curr.y > prev.y ? curr : prev;
                },
                {
                    y: 0
                }
            ),
            maxSpeedData = speed.reduce(
                (prev, curr) => {
                    return curr.y > prev.y ? curr : prev;
                },
                {
                    y: 0
                }
            );
        if (maxGustData.x) {
            totalGustData.push(maxGustData);
        }
        if (maxSpeedData.x) {
            totalSpeedData.push(maxSpeedData);
        }
    }
    highChart.addSeries(
        {
            data: totalSpeedData,
            name: 'Observation - medelvind',
            index: 1
        },
        false
    );
    highChart.addSeries({
        color: '#2b908f',
        dashStyle: 'Dot',
        data: totalGustData,
        name: 'Observation - Max byvind',
        index: 3
    });
}
async function showWindObservationForYear(id, year) {
    const { gust, speed } = await fetchAllStatData({
        url: `/wind/${year}`
    });
    const highChart = Highcharts.chart(
        id,
        Object.assign({}, highChartOptions, {
            subtitle: {
                text: `${year}`
            },
            tooltip: {
                formatter: function () {
                    return (
                        this.y +
                        ' m/s ' +
                        this.key +
                        ' ' +
                        getMonth(new Date(this.x).toISOString())
                    );
                }
            }
        })
    );
    if (gust.length) {
        highChart.addSeries(
            {
                color: '#2b908f',
                dashStyle: 'Dot',
                data: gust,
                name: 'Observation - byvind',
                index: 3
            },
            false
        );
    }
    highChart.addSeries({
        data: speed,
        name: 'Observation - medelvind',
        index: 1
    });
}

async function showWindObservationForMonth(id, year, month) {
    const { gust, speed } = await fetchAllStatData({
        url: `/wind/${year}/${month}`
    });
    const highChart = Highcharts.chart(
        id,
        Object.assign({}, highChartOptions, {
            subtitle: {
                text: `${getMonth(year + month + '15')} ${year}`
            },
            tooltip: {
                formatter: function () {
                    return (
                        this.y +
                        ' m/s ' +
                        this.key +
                        ' ' +
                        getDayAndDate(new Date(this.x).toISOString())
                    );
                }
            }
        })
    );
    if (gust.length) {
        highChart.addSeries(
            {
                color: '#2b908f',
                dashStyle: 'Dot',
                data: gust,
                name: 'Observation - byvind',
                index: 3
            },
            false
        );
    }
    highChart.addSeries({
        data: speed,
        name: 'Observation - medelvind',
        index: 1
    });
}
async function showWindObservationForDay(id, year, month, day) {
    let response = await fetch(apiUrl + `/wind/${year}/${month}/${day}`);
    let { gust, speed } = await response.json();

    let highChart = Highcharts.chart(
        id,
        Object.assign({}, highChartOptions, {
            subtitle: {
                text: getDateHuge(`${year}-${month}-${day}`)
            }
        })
    );
    highChart.addSeries(
        {
            data: speed,
            name: 'Observation - medelvind',
            index: 1
        },
        false
    );
    highChart.addSeries({
        color: '#2b908f',
        dashStyle: 'Dot',
        data: gust,
        name: 'Observation - byvind',
        index: 3
    });
}
async function showWindObservation(highChart) {
    let response = await fetch(apiUrl + '/wind/24h');
    let { gust, speed } = await response.json();
    highChart.addSeries(
        {
            data: speed,
            name: 'Observation - medelvind',
            index: 1
        },
        false
    );
    highChart.addSeries(
        {
            color: '#2b908f',
            dashStyle: 'Dot',
            data: gust,
            name: 'Observation - byvind',
            index: 3
        },
        false
    );
}
async function showWindForecast(highChart, forecastPeriod) {
    let [windGust, windSpeed, windDirection] = await Promise.all(
            ['gust', 'speed', 'direction'].map((sensor) => {
                return fetchGraphData(
                    `/forecast/wind${sensor}/${forecastPeriod}`
                );
            })
        ),
        { gustData, speedData } = addWindDirection(
            windGust,
            windSpeed,
            windDirection
        );
    let forecastDate = DateTime.local().plus({ hour: forecastPeriod });
    highChart.setTitle(
        highChartOptions.title,
        {
            text: `${DateTime.local().toISODate()} - ${forecastDate.toISODate()}`
        },
        false
    );
    highChart.addSeries(
        {
            color: 'red',
            data: speedData,
            name: 'Prognos - medelvind',
            index: 2
        },
        false,
        false
    );
    highChart.addSeries(
        {
            color: 'red',
            dashStyle: 'Dot',
            data: gustData,
            name: 'Prognos - byvind',
            index: 4
        },
        true,
        false
    );
}
async function showWindDetails(highChartId, forecastPeriod = 24) {
    let highChart = Highcharts.chart(highChartId, highChartOptions);
    await showWindObservation(highChart);
    await showWindForecast(highChart, forecastPeriod);
}
export {
    showWindDetails,
    showWindObservationForDay,
    showWindObservationForMonth,
    showWindObservationForYear,
    showWindObservationForAllTime,
    showWindObservationForAllTimePerMonth,
    getWindDirection as getWindSector
};
